import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";

import { type ATeamWorkplaces } from "../types";
import { FacilityCard } from "./card/FacilityCard";
import { FacilityCardGhost } from "./card/FacilityCard.ghost";

interface ATeamListProps {
  workplaces: ATeamWorkplaces;
  isLoading: boolean;
}

function BottomText(props: ATeamListProps) {
  const PRIORITY_PLUS_ZENDESK_ARTICLE_FOR_PROFESSIONALS =
    "https://support.clipboardhealth.com/hc/en-us/articles/27288968266775";

  const { workplaces, isLoading } = props;

  if (isLoading) {
    return null;
  }

  const isATeamWorkplaceExists = Object.keys(workplaces).length > 0;

  if (isATeamWorkplaceExists) {
    return (
      <Stack justifyContent="center" alignItems="center" gap={1}>
        <Text variant="h4" sx={{ fontSize: "18px", fontWeight: "600", color: "#424242" }}>
          Not seeing a workplace you really like?
        </Text>
        <Text variant="body2" sx={{ fontSize: "14px" }}>
          Tap <ExternalLink to={PRIORITY_PLUS_ZENDESK_ARTICLE_FOR_PROFESSIONALS}>here</ExternalLink>{" "}
          for tips on how to be added to a workplace&apos;s A-Team.
        </Text>
      </Stack>
    );
  }

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Text variant="body1">
        You don&apos;t have Priority Plus because no workplaces have selected you to be a part of
        their A-Team. Tap{" "}
        <ExternalLink to={PRIORITY_PLUS_ZENDESK_ARTICLE_FOR_PROFESSIONALS}>here</ExternalLink> for
        tips on how to be added to one.
      </Text>
    </Stack>
  );
}

export function ATeamList(props: ATeamListProps) {
  const { workplaces, isLoading } = props;

  return (
    <Stack gap={isDefined(workplaces) && Object.keys(workplaces).length > 0 ? 3 : 1}>
      <Text
        variant="h2"
        sx={{
          color: "#424242",
          fontSize: "18px",
          fontWeight: "600",
        }}
      >
        Priority Plus Workplaces
      </Text>
      {Object.values(workplaces)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((workplace) => (
          <FacilityCard
            key={workplace.id}
            facilityId={workplace.id}
            facilityName={workplace.name}
            aTeamRosterSize={workplace.aTeamRosterSize}
          />
        ))}
      <BottomText workplaces={workplaces} isLoading={isLoading} />
      {isLoading &&
        Array.from({ length: 5 })
          .fill(undefined)
          // eslint-disable-next-line react/no-array-index-key
          .map((_, index) => <FacilityCardGhost key={index} />)}
    </Stack>
  );
}
